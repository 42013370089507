




















































import Vue from "vue";
import { Getter, Action } from "vuex-class";
import { Component, Ref } from "vue-property-decorator";
import router from "@/core/router";

// todo make actual slides
@Component
export default class Onboarding extends Vue {
  @Ref("slider") slider!: HTMLDivElement;
  @Getter("profile/userEmailVerified") isEmailVerified!: boolean;
  @Action("profile/completeOnboarding")
  completeOnboarding!: () => Promise<void>;

  slide = 0;
  doNotShowAgain = false;
  slides = [
    {
      title: this.$t("onboardingSlides.firstSlide.title") as string,
      subtitle: this.$t("onboardingSlides.firstSlide.subtitle") as string,
      text: this.$t("onboardingSlides.firstSlide.text") as string,
    },
    {
      title: this.$t("onboardingSlides.secondSlide.title") as string,
      subtitle: this.$t("onboardingSlides.secondSlide.subtitle") as string,
      text: this.$t("onboardingSlides.secondSlide.text") as string,
    },
    {
      title: this.$t("onboardingSlides.thirdSlide.title") as string,
      subtitle: this.$t("onboardingSlides.thirdSlide.subtitle") as string,
      text: this.$t("onboardingSlides.thirdSlide.text") as string,
    },
    // etc...
  ];

  async onStartClick() {
    if (this.doNotShowAgain) await this.completeOnboarding();

    router.push("/dashboard");
  }

  back() {
    const last = this.slides.length - 1;
    const next = this.slide - 1 < 0 ? last : this.slide - 1;
    const width = this.slider.clientWidth;
    this.slider.scrollTo({ left: next * width, behavior: "smooth" });
  }
  fwd() {
    const last = this.slides.length - 1;
    const next = this.slide + 1 > last ? 0 : this.slide + 1;
    const width = this.slider.clientWidth;
    this.slider.scrollTo({ left: next * width, behavior: "smooth" });
  }
  jumpTo(index: number) {
    const width = this.slider.clientWidth;
    this.slider.scrollTo({ left: index * width, behavior: "smooth" });
  }
  mounted() {
    let isScrolling: any;
    const width = this.slider.clientWidth;
    this.slider.onscroll = () => {
      clearTimeout(isScrolling);
      isScrolling = setTimeout(() => {
        clearTimeout(isScrolling);
        const offset = this.slider.scrollLeft;
        const slide = Math.round(offset / width);
        this.slide = slide;
        this.slider.scrollTo({ left: slide * width, behavior: "smooth" });
      }, 66);
    };
  }
}
